.questions {
   margin-top: -100px;

   &--container {
      @media (max-width: 450px) {
         padding: 0px 0px;
      }
   }

   &__wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 1130px) {
         justify-content: center;
      }
   }

   &__items {
      position: relative;
      z-index: 2;
      max-width: 751px;
      width: 100%;
      margin-right: 30px;
      @media (max-width: 1130px) {
         margin-right: 0;
      }
      @media (max-width: 450px) {
         padding: 0px 10px 0px 10px;
      }
   }

   &__item {
      width: 100%;
      padding: 30px 45px 30px 27px;
      margin: 0px 0px 24px 0px;
      box-shadow: 0 3px 50px rgba(0, 0, 0, 0.2);
      border-radius: 25px;
      background-color: #ffffff;
   }

   &__item-title {
      position: relative;
      color: #282828;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      padding: 0px 10px 0px 0px;
      cursor: pointer;
      &:after {
         content: "";
         transition: all 1s;
         position: absolute;
         top: -5px;
         right: -15px;
         width: 25px;
         height: 25px;
         display: flex;
         justify-content: center;
         align-items: center;
         background-image: url("../images/icon/arrow-down-q.png");
         background-repeat: no-repeat;
         background-position: center center;
      }
   }

   &__item-text {
      padding: 40px 0px 29px 0px;
      margin: 0;
      display: none;
      color: #138188;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.2px;
      &--positon {
         padding: 0px 0px 29px 0px;
      }
      &--link {
         color: #138188;
         font-weight: 700;
         letter-spacing: 1.2px;
         padding: 0px 0px 0px 0px;
      }
      a {
         color: #138188;
         font-weight: 700;
         letter-spacing: 1.2px;
         text-decoration: underline;
      }
   }
   &__item.questions__item--active {
      .questions__item-title {
         &:after {
            background-image: url("../images/icon/arrow-up-q.png");
         }
      }
      .questions__item-text {
         display: block;
      }
   }
}

.questions {
   &__forms {
      max-width: 319px;
      width: 100%;
      @media (max-width: 1130px) {
         margin-bottom: 30px;
      }
      @media (max-width: 450px) {
         max-width: 100%;
      }
   }
}
.form {
   text-align: center;
   padding: 34px 25px 50px 25px;
   border-radius: 25px;
   background-color: #ffffff;
   background-image: linear-gradient(-200deg, #ffb03c 0%, #ff950d 100%);

   &__title {
      margin: 0px 0px 17px 0px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1.8px;
   }

   &__text {
      max-width: 204px;
      width: 100%;
      margin: 0 auto 30px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.4px;
   }

   &__input {
      display: block;
      width: 100%;
      border-radius: 28px;
      padding: 20px 30px 20px 30px;
      background-color: transparent;
      border: 1px solid #ffffff;
      margin-bottom: 15px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.8px;
      color: #138188;
      &::placeholder {
         color: #ffffff;
      }
      &:focus {
         border: 1px solid #138188;
      }
   }

   &__btn {
      margin-top: 35px;
      border: none;
   }
}
