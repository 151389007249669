.logic {
   min-height: 1200px;
   background-repeat: no-repeat;
   background-size: cover;
   margin-top: -200px;
   padding: 300px 0px 250px 0px;

   @media (max-width: 1920px) {
      min-height: 1260px;
   }

   @media (max-width: 647px) {
      margin-top: -300px;
      padding: 300px 0px 105px 0px;
   }

   &__inner {
      position: relative;
      padding-top: 61px;
   }

   &__title {
      color: #ffffff;
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 67px;
      @media (max-width: 1125px) {
         margin-top: 30px;
         text-align: center;
      }
      @media (max-width: 747px) {
         max-width: 300px;
         margin: 150px auto 30px;
      }
   }

   &__wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      @media (max-width: 1125px) {
         justify-content: center;
      }
   }

   &__spoiler {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      max-width: 345px;
      width: 100%;
      min-height: 228px;
      padding: 33px 0px 0px 0px;
      border-radius: 15px;
      background-color: #ffffff;
      & + & {
         margin-left: 32px;
      }
      @media (max-width: 1125px) {
         margin-bottom: 50px;
      }
      @media (max-width: 740px) {
         & + & {
            margin-left: 0;
         }
      }
   }

   &__spoiler-box {
      &.active {
         .logic__spoiler-arrow {
            transform: rotate(-180deg);
         }
      }
   }

   &__spoiler-step {
      color: #06827f;
      font-size: 40px;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: 4px;
      font-weight: 900;
      display: block;
      margin-bottom: 40px;
      text-align: center;
      cursor: pointer;
   }

   &__spoiler-descr {
      color: #06827f;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 1.6px;
      text-align: center;
   }

   &__spoiler-arrow {
      position: absolute;
      bottom: -30px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 10px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #000;
      background: linear-gradient(-200deg, #ffb03c 0%, #ff950d 100%);
      transition: all 0.3s;
      cursor: pointer;
      @media (max-width: 747px) {
         z-index: 3;
      }
   }

   &__content {
      display: none;
      position: absolute;
      top: 130px;
      z-index: 2;
      background-color: #ffffff;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      margin: 40px 0px 0px 0px;
      padding: 0px 25px 0px 25px;

      @media (max-width: 747px) {
         position: relative;
         top: 0;
      }
   }

   &__content-box {
      margin-bottom: 38px;
   }

   &__content-title {
      color: #06827f;
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 1.6px;
      padding: 0px 0px 9px 0px;
      margin: 0px 0px 14px 0px;
      position: relative;
      &:after {
         content: "";
         position: absolute;
         bottom: 0;
         left: 0;
         max-width: 216px;
         width: 100%;
         height: 1px;
         background-color: #1e9b9c;
      }
   }

   &__content-text {
      color: #06827f;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 1.4px;
      padding: 0px 0px 24px 0px;
      &:last-child {
         padding: 0px 0px 0px 0px;
      }
   }

   &__item {
      margin-top: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @media (max-width: 1125px) {
         justify-content: center;
      }
      @media (max-width: 740px) {
         text-align: center;
      }
      @media (max-width: 500px) {
         margin-top: 30px;
      }
   }

   &__item-text {
      max-width: 540px;
      width: 100%;
      color: #ffffff;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 1.4px;
      @media (max-width: 1064px) {
         margin-bottom: 30px;
      }
   }

   &__item-btn {
      margin-right: 84px;
      @media (max-width: 1064px) {
         margin-right: 0;
      }
   }
}
.logic-item {
   &__link {
      position: relative;
      display: inline-block;
      margin-right: 11px;
      padding: 13px 30px 14px 30px;
      border-radius: 23px;
      border-radius: 23px;
      border: 1px solid #ff950d;
      background-color: transparent;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.6px;
      transition: all 1s;
      &:hover,
      &:focus {
         opacity: 0.7;
      }

      @media (max-width: 740px) {
         margin-bottom: 20px;
      }

      @media (max-width: 500px) {
         display: block;
      }
   }
}
