.home {
   padding: 55px 0px 20px 0px;
   &--account {
      padding: 35px 0px 20px 0px;
      // padding: 30px 0px 10px 0px;

      // @media (max-width: 400px) {
      //    padding: 0px 0px 10px 0px;
      // }
   }

   &__inner {
      display: flex;
      justify-content: space-between;
   }

   &__slogan {
      max-width: 600px;
      width: 100%;
      color: #ffffff;
      &--faq {
         margin-top: 40px;
         max-width: 303px;
      }
      @media (max-width: $md3+px) {
         margin-left: 40px;
      }
      @media (max-width: 400px) {
         margin-left: 10px;
      }
   }

   &__header-title {
      margin-bottom: 13px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
   }

   &__title {
      margin: 0px 0px 18px -2px;
      font-size: 36px;
      font-weight: 800;
      line-height: 38px;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      @media (max-width: 800px) {
         font-size: 25px;
      }
      &--faq {
         @media (max-width: 400px) {
            font-size: 22px;
         }
      }
   }

   &__text {
      margin-bottom: 29px;
      max-width: 385px;
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1.8px;
   }

   &__btn {
      &--faq {
         display: none;
         margin-top: 46px;
         @media (max-width: 870px) {
            display: inline-block;
         }
         @media (max-width: 400px) {
            margin-top: 76px;
         }
      }
   }

   &__figure {
      max-width: 436px;
      flex: 0 1 436px;
      margin-right: 4px;
      transform: translateY(-28px);
      &--faq {
         max-width: 643px;
         flex: 0 1 643px;
         transform: translateY(-10px) rotate(-20deg);
      }
      img {
         width: 100%;
      }
      @media (max-width: $md3+px) {
         display: none;
      }
   }
}

.home {
   // .home__breadcrumbs

   &__breadcrumbs {
      display: none;
      @media (max-width: 870px) {
         display: flex;
      }
      @media (max-width: $md3+px) {
         margin-left: 40px;
      }
      @media (max-width: 400px) {
         margin-left: 10px;
      }
   }

   // .home__breadcrumbs-item

   &__breadcrumbs-item {
      & + & {
         margin-left: 36px;
      }
   }

   // .home__breadcrumbs-link

   &__breadcrumbs-link {
      color: #ff960e;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      position: relative;
      &--white {
         color: #fff;
      }
      &:after {
         content: "";
         position: absolute;
         top: 2px;
         right: -20px;
         width: 6px;
         height: 12px;
         background-image: url("../images/icon/right-arrow.png");
         background-repeat: no-repeat;
         background-size: cover;
      }
      &--active {
         color: #ffffff;
         text-decoration: none;
         &:after {
            content: none;
         }
      }
   }
}
