@import "local-fonts";

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Black.woff2") format("woff2");
   src: url("../fonts/Gilroy-Black.woff") format("woff");
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Extrabold.woff2") format("woff2");
   src: url("../fonts/Gilroy-Extrabold.woff") format("woff");
   font-weight: 800;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Bold.woff2") format("woff2");
   src: url("../fonts/Gilroy-Bold.woff") format("woff");
   font-weight: 700;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Semibold.woff2") format("woff2");
   src: url("../fonts/Gilroy-Semibold.woff2") format("woff");
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Bold.woff2") format("woff2");
   src: url("../fonts/Gilroy-Bold.woff") format("woff");
   font-weight: 700;
   font-style: normal;
   font-display: swap;
}
@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Regular.woff2") format("woff2");
   src: url("../fonts/Gilroy-Regular.woff") format("woff");
   font-weight: 400;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-RegularItalic.woff2") format("woff2");
   src: url("../fonts/Gilroy-RegularItalic.woff2") format("woff");
   font-weight: 400;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: "Gilroy", sans-serif;
   src: url("../fonts/Gilroy-Light.woff2") format("woff2");
   src: url("../fonts/Gilroy-Light.woff") format("woff");
   font-weight: 300;
   font-style: normal;
   font-display: swap;
}
