@import "vars";
@import "fonts";
@import "global";

//<МИКСИНЫ>===============================================================================================
@import "mixin";
//</МИКСИНЫ>===============================================================================================

//<ШРИФТЫ>===============================================================================================

//<Подключаем шрифты>=======================================================================================

//</Подключаем шрифты>=======================================================================================

//<Иконочные шрифты>==========================================================================================

//</Иконочные шрифты>==========================================================================================

//<Шрифт по умолчанию>==========================================================================================
$fontfamily: "Gilroy";
//</Шрифт по умолчанию>==========================================================================================

//</ШРИФТЫ>=======================================================================================================

//<ПЕРЕМЕННЫЕ>===========================================================================================================
$minwidth: 320px;
$mw: 1130;
$md1: $mw + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
//</ПЕРЕМЕННЫЕ>===========================================================================================================

//<ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================
body {
   color: #000;
   font-size: 14px;
   font-family: "Gilroy", sans-serif;
   &.lock {
      overflow: hidden;
   }
}
//</ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================

//<ОБОЛОЧКА>===========================================================================================================
.wrapper {
   width: 100%;
   min-height: 100%;
   overflow: hidden;
   &.loaded {
   }
}
//</ОБОЛОЧКА>===========================================================================================================

//<ОСНОВНАЯ СЕТКА>===========================================================================================================
.container {
   max-width: $mw + px;
   margin: 0 auto;
   width: 100%;
   padding: 0 15px;
   // @media (max-width: $md1+px) {
   //    max-width: 970px;
   // }
   @media (max-width: $md3+px) {
      padding: 0 10px;
   }
}
//</ОСНОВНАЯ СЕТКА>===========================================================================================================

//<ПОДКЛЮЧЕНИЕ ФАЙЛОВ UI СТИЛЕЙ, ФОРМ И ГОТОВЫХ КЛАССОВ>====================================================================================================
@import "forms.scss";
@import "ui.scss";
//</ПОДКЛЮЧЕНИЕ ФАЙЛОВ UI СТИЛЕЙ, ФОРМ И ГОТОВЫХ КЛАССОВ>====================================================================================================

//<ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>====================================================================================================
@import "header.scss";
@import "home.scss";
@import "system.scss";
@import "documents.scss";
@import "how-start.scss";
@import "logic.scss";
@import "regulations.scss";
@import "questions.scss";
@import "agree.scss";
@import "profile.scss";
@import "footer.scss";
//</ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>====================================================================================================

//<ОСНОВНОЙ БЛОК>====================================================================================================
.main-header {
   position: relative;
   min-height: 750px;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: top center;

   &--account {
      min-height: 415px;
      background-color: #f8f8f8;
   }

   &--agreement {
      @media (max-width: 450px) {
         min-height: 500px;
      }
   }
   &--home {
      @media (max-width: 1920px) {
         min-height: 750px;
      }
   }
}

.regulations__card {
   &::-webkit-scrollbar {
      width: 3px;
      height: 0;
   }

   &::-webkit-scrollbar-track {
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      background-color: #ff950e;
   }
}
//====================================================================================================

//</ОСНОВНОЙ БЛОК>====================================================================================================
