//<BUTTONS (a.btn+tab)>====================================================================================================
.btn {
   position: relative;
   display: inline-block;
   margin-left: -3px;
   padding: 13px 51px 14px 25px;
   border-radius: 23px;
   background-image: linear-gradient(-200deg, #ffb03c 0%, #ff950d 100%);
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   letter-spacing: 1.6px;
   transition: all 1s;
   &--register {
      background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
   }
   &--white {
      box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
      color: #f38e0d;
      background-image: none;
      background-color: #fff;
      font-size: 14px;
      &:after {
         content: "";
         background-image: url("../images/icon/arrow-orange.png") !important;
      }
   }

   &::after {
      content: "";
      position: absolute;
      right: 16px;
      top: 11px;
      width: 25px;
      height: 22px;
      background-image: url("../images/icon/arrow-right.png");
   }
   &:hover,
   &:focus {
      opacity: 0.7;
   }
   &--arrow {
      color: #00747f;
      &:after {
         content: "";
         background-image: url("../images/icon/arrow-down-q.png") !important;
         transform: rotate(-90deg);
         background-repeat: no-repeat;
         right: 10px;
         top: 4px;
         width: 30px;
         height: 30px;
      }
   }
   &.fw {
      width: 100%;
   }
}
//</BUTTONS>====================================================================================================
.modal {
   display: none;
   max-width: 410px;
   width: 100%;
   border-radius: 25px;
   background-color: #ffffff;
   // .modal__wrapper

   &__wrapper {
   }

   // .modal__tabs

   &__tabs {
      display: flex;
      flex-wrap: wrap;
   }

   // .modal__tab

   &__tab {
      position: relative;
      max-width: 182px;
      width: 100%;
      border-radius: 25px 0 0;
      padding: 31px 41px 31px 74px;
      background-color: #ffffff;
      background-image: linear-gradient(-200deg, #ffb03c 0%, #ff950d 100%);
      color: #ffffff;
      font-size: 18px;
      font-weight: 800;
      line-height: 38px;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      @media (max-width: 450px) {
         max-width: 100%;
      }

      &.active {
         background-image: none;
         background-color: #ffffff;
      }
      &--entr {
         &.active {
            color: #ffb03c;
            &:after {
               content: "";
               background-image: url("../images/icon/user_active.png");
            }
         }
         &:after {
            content: "";
            position: absolute;
            left: 33px;
            top: 36px;
            width: 26px;
            height: 26px;
            background-image: url("../images/icon/user.png");
         }
      }
      &--register {
         max-width: 228px;
         width: 100%;
         padding: 31px 30px 31px 61px;
         border-radius: 0 25px 0 0;
         background-color: #ffffff;
         background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
         @media (max-width: 450px) {
            max-width: 100%;
            border-radius: 0;
         }
         &.active {
            color: #33a987;
            &:after {
               content: "+";
               color: #33a987;
            }
         }
         &:after {
            content: "+";
            position: absolute;
            left: 16px;
            top: 25px;
            color: #ffffff;
            font-size: 45px;
            font-weight: 800;
         }
      }
   }

   // .modal__tab-content

   &__tab-content {
      padding: 40px 43px 70px 43px;
   }

   // .modal__tab-item

   &__tab-item {
      display: none;
      &:first-child {
         display: block;
      }
   }

   // .modal__tab-form

   &__tab-form {
      text-align: center;
   }

   // .modal__tab-input

   &__tab-input {
      display: block;
      width: 100%;
      padding: 20px 27px 20px 27px;
      border-radius: 28px;
      border: 1px solid #acacac;
      margin: 0px 0px 15px 0px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 1.8px;
      color: #ffa122;

      &:focus {
         border: 1px solid #ffa122;
      }
      &::placeholder {
         color: #afafaf;
      }
   }

   // .modal__tab-btn

   &__tab-btn {
      margin-top: 11px;
      border: none;
   }
}
.active {
}
.active-tab {
}
.btn {
}

.fancybox-content {
   padding: 0px 0px 0px 0px;
}

.fancybox-button svg {
   display: none;
}

.form {
}
.form-row {
   @include flexbox();
   margin: 0px -15px;
}
.form__column {
   padding: 0px 15px;
}
.form-input {
}
.form-button {
}
//</FORM>====================================================================================================

//<BUTTONS>====================================================================================================

//</BUTTONS>====================================================================================================

//<SELECT>====================================================================================================
.select-block {
   position: relative;
}
.select__label {
   z-index: 2;
   position: absolute;
   top: -8px;
   background-color: #f7f7f7;
   padding: 0 5px;
   color: #979797;
   font-weight: 600;
   font-size: 13px;
   line-height: 16px;
   left: 20px;
}
.select {
   position: relative;
   cursor: pointer;
   .select-title {
      z-index: 1;
      width: 100%;
      height: 55px;
      border: 1px solid #dedede;
      position: relative;
      background-color: #fff;
   }
   .select-title__arrow {
      position: absolute;
      top: 0;
      right: 0px;
      width: 45px;
      height: 100%;
      background: url("../img/icons/select-arrow.svg") center no-repeat;
   }
   .select-title__value {
      color: #000;
      font-weight: 700;
      font-size: 18px;
      line-height: 53px;
      display: block;
      overflow: hidden;
      padding: 0 45px 0 25px;
      white-space: nowrap;
      text-transform: none;
      text-overflow: ellipsis;
   }
   .select-options {
      position: absolute;
      z-index: 10;
      top: 54px;
      display: none;
      overflow: hidden;
      min-width: 100%;
      background-color: #fff;
      border: 1px solid #dedede;
      padding: 10px 20px 10px 25px;
   }
   .select-options-scroll {
      max-height: 80px;
      overflow: auto;
   }
   .select-options-list {
      padding: 0px 0px 0px 0px;
   }
   .select-options__value {
      color: #000;
      font-size: 20px;
      margin: 0px 0px 15px 0px;
      &:last-child {
         margin: 0px 0px 0px 0px;
      }
      &:hover {
         color: #000;
      }
   }
   &.form-block__select {
      .select-title {
      }
   }
}
//</SELECT>====================================================================================================

//<INPUT>====================================================================================================
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}
.input {
   height: 50px;
   border-radius: 0 !important;
   margin: 0px 0px 20px 0px;
   width: 100%;
   display: block;
   padding: 0px 20px;
   &.focus {
   }
   &.err {
      border: 1px solid red;
   }
}
textarea.input {
   resize: none;
   padding: 0px 0px;
}
//</INPUT>====================================================================================================

//<CHECK>====================================================================================================
.check {
   position: relative;
   padding-left: 25px;
   color: #5f5f5f;
   line-height: 18px;
   cursor: pointer;
   input {
      display: none;
   }
   &:before {
      content: "";
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #a3a3a3;
      position: absolute;
   }
   &.active {
      &:before {
         background: #000;
      }
   }
}
//</CHECK>====================================================================================================

//<OPTIONS>====================================================================================================
.option {
   position: relative;
   cursor: pointer;
   padding-left: 25px;
   line-height: 16px;
   margin-bottom: 10px;
   font-size: 15px;
   font-weight: 300;
   &:last-child {
      margin-bottom: 0px;
   }
   input {
      display: none;
   }
   &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      content: "";
      border: 1px solid #7f7f7f;
      border-radius: 50%;
   }
   &.active {
      &:before {
         background: #000;
      }
   }
}
//</OPTIONS>====================================================================================================

//<RATING>====================================================================================================
.rating-block {
   display: table;
   font-size: 0;
}
.rating {
   display: inline-block;
   position: relative;
   vertical-align: middle;
   &.edit .star {
      cursor: pointer;
   }
   .star {
      width: 17px;
      height: 14px;
      display: inline-block;
      position: relative;
      z-index: 3;
   }
}
.rating__line {
   position: absolute;
   width: 85px;
   height: 14px;
   top: 0;
   left: 0;
   background: url("../img/icons/bg_rating.svg") 0 0 no-repeat;
   z-index: 1;
   background-size: 85px 100%;
}
.rating__activeline {
   position: absolute;
   width: 0px;
   height: 14px;
   top: 0;
   left: 0;
   background: url("../img/icons/bg_rating_active.svg") 0 0 no-repeat;
   z-index: 2;
   background-size: 85px 100%;
}
.rating__value {
   display: inline-block;
   color: #1b3139;
   font-size: 14px;
   line-height: 13px;
   vertical-align: middle;
}
//</RATING>====================================================================================================

//<QUANTITY>====================================================================================================
.quantity {
   position: relative;
   width: 73px;
   height: 47px;
   border: 1px solid #cfcfcf;
   background-color: #ffffff;
}
.quantity__input {
   height: 45px;
   width: 50px;
   text-align: center;
   color: #101010;
   font-weight: 300;
}
.quantity__btn {
   position: absolute;
   top: 11px;
   right: 15px;
   cursor: pointer;
   &:before {
      font-size: 25px;
      display: inline-block;
      line-height: 10px;
      color: #a9a9a9;
   }
   &:hover {
      &:before {
         color: #da0005;
      }
   }
   &.up {
   }
   &.dwn {
      top: 25px;
   }
}
//</QUANTITY>====================================================================================================

//<RANGE>====================================================================================================
.category-params-item-price {
}
.category-params-item-price-table {
   margin-bottom: 25px;
   .cell {
      &:first-child {
         padding-right: 15px;
         position: relative;
         &:after {
            content: "-";
            position: absolute;
            right: -4px;
            top: 8px;
            font-size: 20px;
         }
      }
      &:last-child {
         padding-left: 15px;
      }
      input {
         height: 37px;
         border: 2px solid #393939;
         background-color: #ffffff;
         text-align: center;
         font-size: 12px;
         color: #393939;
         font-weight: 500;
         width: 100%;
      }
   }
}
.category-params-item-price-range {
   margin: 0 12px 40px 12px;
   background-color: #c9cecf;
   height: 2px;
   position: relative;
   .ui-slider-range {
      background-color: #000;
      height: 2px;
      position: relative;
   }
   .ui-slider-handle {
      width: 23px;
      height: 28px;
      border: 3px solid #fbfbfb;
      background-color: #ea5922;
      display: block;
      position: absolute;
      top: -14px;
      margin-left: -12px;
      cursor: pointer;
      span {
         position: absolute;
         bottom: -18px;
         left: 50%;
         width: 50px;
         text-align: center;
         font-size: 10px;
         margin-left: -25px;
         font-weight: 500;
         color: #94a6aa;
      }
   }
}

.form-range {
}
.form__info {
   position: absolute;
   width: 60px;
   height: 60px;
   right: 0;
   top: 0;
   line-height: 60px;
   text-align: center;
   color: #888;
   z-index: 2;
   font-size: 14px;
   @media (max-width: $md3+px) {
      width: 50px;
      height: 50px;
      line-height: 50px;
   }
}
.form-range-line {
   position: relative;
   height: 5px;
   border-radius: 0 0 4px 4px;
   background-color: #e0e0e0;
   margin: -2px 0px 0px 0px;
   .ui-slider-range {
      background-color: #093454;
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 0 4px 4px;
   }
   .ui-slider-handle {
      background: url("../img/icons/range.svg") 0 0 no-repeat;
      width: 28px;
      height: 28px;
      position: absolute;
      margin: -9px 0px 0px -14px;
      top: 0;
      left: 0;
      cursor: pointer;
   }
   .ui-state-hover {
      background: url("../img/icons/range_a.svg") 0 0 no-repeat;
   }
}
.form-range-values {
   @include flexbox();
   justify-content: space-between;
   padding: 14px 0px 0px 0px;
}
.form-range-values__item {
   font-size: 12px;
   color: #888;
}
//</RANGE>====================================================================================================
