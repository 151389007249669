.system {
   margin-bottom: 41px;
   &__wrapper {
      margin: -50px -50px 0px -50px;
      @media (max-width: 880px) {
         margin: -90px -10px 0px -10px;
      }
   }

   &__inner {
      padding: 0 30px;
      text-align: center;
      @media (max-width: 880px) {
         padding: 0 10px;
      }
   }

   .slick-dots {
      font-size: 0;
      display: inline-flex;
      margin-top: 17px;
      li {
         margin-left: 4px;
         button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #11757d;
         }
         &.slick-active {
            button {
               background-color: #11757d;
            }
         }
      }
   }
}

.advantages {
   display: flex;
   flex-direction: column;
   max-width: 183px;
   width: 100%;
   min-height: 265px;
   text-align: center;

   &__box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 24px;
      max-width: 160px;
      width: 100%;
      min-height: 160px;
      border-radius: 50%;
      background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
      &::after {
         content: "";
         position: absolute;
         top: 5px;
         left: -14px;
         max-width: 160px;
         width: 100%;
         min-height: 160px;
         border-radius: 50%;
         background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
         opacity: 0.15;
      }
   }

   &__title {
      color: #282828;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      margin-bottom: 14px;
   }

   &__text {
      display: flex;
      align-items: flex-end;
      flex: 1 1 auto;
      color: #282828;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 1.2px;
   }
}
