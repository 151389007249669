.regulations {
   padding: 100px 0px 20px 0px;
   @media (max-width: 1130px) {
      padding: 150px 0px 0px 0px;
   }

   .container {
      @media (max-width: 500px) {
         padding: 0px 0px;
      }
   }

   // .regulations__box

   &__inner {
      margin-top: -60px;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 1080px) {
         justify-content: center;
         & + & {
            margin-left: 30px;
         }
      }
   }

   // .regulations__card

   &__card {
      max-width: 346px;
      width: 100%;
      height: 405px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 29px 24px 29px 24px;
      margin: 0px 0px 29px 0px;
      box-shadow: 0 3px 50px rgba(0, 0, 0, 0.2);
      border-radius: 25px;
      background-color: #ffffff;
      position: relative;
      &:after {
         content: "";
         position: absolute;
         bottom: 0;
         max-width: 346px;
         width: 100%;
         height: 60px;
         background-image: linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      }
      @media (max-width: 1080px) {
         & + & {
            margin-left: 30px;
         }
      }
      @media (max-width: 740px) {
         & + & {
            margin-left: 0px;
         }
      }
   }

   // .regulations__card-title

   &__card-title {
      color: #282828;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      margin-bottom: 15px;
   }

   // .regulations__card-descr

   &__card-descr {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #138188;
      font-weight: 400;
      letter-spacing: 1.2px;
      margin-bottom: 24px;

      &:last-child {
         margin-bottom: 0;
      }

      &--italic {
         font-weight: 400;
         font-style: italic;
      }
      span {
         color: #138188;
         font-size: 14px;
         font-weight: 700;
      }
   }
}
.title-box {
   // .title-box__head

   &__head {
   }

   // .title-box__slogan

   &__slogan {
   }

   // .title-box__descr

   &__descr {
   }
}
.title-box--big {
}
