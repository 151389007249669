//====================================================================================================
.header {
   &__row {
      height: 100px;
      display: flex;
      align-items: center;
   }

   &__menu {
      flex: 1 1 auto;
      margin-bottom: 23px;
   }

   &__logo {
      position: relative;
      z-index: 5;
      flex: 1 1 auto;
      display: block;

      img {
         width: 153px;
         height: 153px;
      }
   }

   &__fix {
      display: none;
   }
   &__item {
      & + & {
         margin-left: 31px;
      }

      &--small {
         margin-left: 20px;
      }

      @media (max-width: $md3+px) {
         & + & {
            margin-left: 0;
            margin-bottom: 23px;
         }
      }
   }
   &__user {
      display: none;
      position: relative;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.4px;
      margin-top: -30px;
      &:after {
         content: "";
         position: absolute;
         top: -3px;
         right: -20px;
         width: 20px;
         height: 20px;
         background-image: url("../images/icon/logout-white.png");
      }
      &--active {
         @media (max-width: 905px) {
            display: flex;
            max-width: 100px;
            width: 100%;
            margin: -30px auto 30px;
         }

         @media (max-width: 732px) {
            margin: -70px auto 70px;
         }
         @media (max-width: 430px) {
            max-width: 100%;
            justify-content: flex-end;
            margin: -20px 0px 0px -40px;
            &:after {
               right: -30px;
            }
         }
      }
   }
}
.menu {
   &__body,
   &__fix {
      @media (max-width: $md3+px) {
         z-index: 5;
         background-color: #000000;
         background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
         position: fixed;
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;
         overflow: auto;
         opacity: 0;
         visibility: hidden;
         padding: 150px 0px 20px 0px;
         transition: all 1s;
         text-align: center;
         &.active {
            opacity: 1;
            visibility: visible;
         }
      }
   }

   &__list {
      display: inline-flex;
      align-items: center;
      height: 20px;

      @media (max-width: $md3+px) {
         display: flex;
         flex-direction: column;
      }
   }

   &__link {
      position: relative;
      color: #ffffff;
      font-weight: 400;
      letter-spacing: 1.4px;
      transition: all 1s;
      border-radius: 14px;
      border: 1px solid transparent;
      padding: 4px 15px 4px 15px;
      &--bottom {
         margin-left: 1px;
         margin-right: -12px;
      }

      @media (max-width: $md3+px) {
         font-size: 22px;
         font-weight: 400;
         letter-spacing: 2.2px;
         &--bottom {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            bottom: 50px;
            margin: 0 auto;
            left: 0;
            right: 0;
            picture {
               margin-top: 5px;
               margin-right: 5px;
            }
         }
      }
      @media (min-width: 992px) {
         &:hover,
         &:focus,
         &:active {
            border: 1px solid #ffffff;
         }
      }
   }
}
.account {
   margin-bottom: 24px;
   &__unregistered,
   &__registered {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.4px;
   }

   &__unregistered {
      &--off {
         display: none;
      }
   }

   &__registered {
      display: none;
      &--active {
         display: inline-block;
         .header__unregistered {
            display: none;
         }
      }
   }
   &__link {
      position: relative;
      border-radius: 17px;
      border: 1px solid hsl(0, 0%, 100%);
      padding: 9px 15px 9px 40px;
      margin-right: 2px;

      &::after {
         content: "";
         position: absolute;
         z-index: 5;
         left: -2px;
         top: 0;
         width: 34px;
         height: 34px;
         background-size: cover;
         background-repeat: no-repeat;
         background-image: url(../images/icon/icon-lock.png);
      }
   }

   @media (max-width: 920px) {
      a {
         min-height: 40px;
         border: none;
         span {
            display: none;
         }
      }
   }
   @media (max-width: $md3+px) {
      a {
         &::after {
            left: -50px;
         }
      }
   }
   @media (max-width: 340px) {
      a {
         padding: 9px 15px 9px 20px;
         &::after {
            left: -50px;
         }
      }
   }
}

//====================================================================================================

.icon-menu,
.icon-fix {
   display: none;
   position: absolute;
   top: 28px;
   right: 23px;
   width: 30px;
   height: 18px;
   cursor: pointer;
   z-index: 10;
   span {
      @include anim(0.3s, 0s);
      top: 8px;
      left: 0px;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      &:first-child {
         top: 0px;
      }
      &:last-child {
         top: auto;
         bottom: 0px;
      }
   }
   &.active {
      span {
         @include scale(0);
         &:first-child {
            @include rotate(-45deg);
            top: 8px;
         }
         &:last-child {
            @include rotate(45deg);
            bottom: 8px;
         }
      }
   }
   @media (max-width: $md3+px) {
      display: block;
   }
}
.icon-fix {
   top: 18px;
}
.header__fixsed {
   display: none;
   &.active {
      position: fixed;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 20px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      background-color: rgba(#000000, 0.5);
      .header__menu {
         margin-bottom: 0;
      }

      .header__account {
         margin-bottom: 0;
      }
      .header__fix {
         display: block;
         max-width: 256px;
         width: 100%;
         height: 111px;
         @media (max-width: 420px) {
            max-width: 155px;
            width: 100%;
            height: 74px;
         }
      }
      .header__logo-img {
         display: none;
      }

      .icon-menu {
         @media (max-width: $md3+px) {
            top: 15px;
         }
      }

      .menu__list {
         @media (max-width: 900px) {
            li + li {
               margin-left: 30px;
            }
         }
         @media (max-width: 760px) {
            li + li {
               margin-left: 0px;
            }
         }
      }
   }
}

.header-user {
   position: absolute;
   bottom: 0;
   color: #ffffff;
   font-size: 14px;
   font-weight: 600;
   line-height: 38px;
   letter-spacing: 1.4px;
}
