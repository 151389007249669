.profile {
   margin-top: -150px;
   padding: 0px 0px 50px 0px;
   background-color: #f8f8f8;

   @media (max-width: 1123px) {
      margin-top: -130px;
   }

   @media (max-width: 790px) {
      margin-top: -230px;
      .container {
         padding: 0 0;
      }
   }

   &__inner {
      position: relative;
      z-index: 2;
      display: flex;
      @media (max-width: 1123px) {
         flex-wrap: wrap;
         justify-content: center;
      }
   }

   &__user {
      max-width: 319px;
      width: 100%;
      margin-right: 30px;
      @media (max-width: 1123px) {
         margin-right: 0;
      }
   }

   &__room {
      max-width: 750px;
      width: 100%;
   }
}

.user {
   text-align: center;

   &__card {
      position: relative;
      padding: 23px 0px 23px 0px;
      border-radius: 25px;
      background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
      margin-bottom: 40px;

      &:after {
         content: "";
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         width: 100%;
         height: 100%;
         border-radius: 25px;
         background-color: rgba(0, 0, 0, 0.25);
      }
   }

   &__name {
      display: flex;
      margin-left: auto;
      max-width: 103px;
      width: 100%;
      padding: 9px 5px 8px 23px;
      color: #002e32;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.2px;
      border-radius: 13px 0 0 13px;
      background-color: #f8f8f8;
   }

   &__link {
      display: block;
      margin-top: -32px;
      margin-bottom: 16px;
   }

   &__static {
      display: block;
      color: #fffefe;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.4px;
      margin-bottom: 24px;
   }

   &__mail {
      display: block;
      color: #fffefe;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.4px;
      margin-bottom: 27px;
   }

   &__card-box {
      position: relative;
      z-index: 3;
      &--color {
         margin-top: 28px;
         padding: 0px 23px 0px 23px;
      }
   }

   &__card-item {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.2px;

      & + & {
         margin-top: 28px;
      }
   }

   &__card-sum {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 2px;
      span {
         font-size: 14px;
         font-weight: 700;
         letter-spacing: 1.4px;
      }
   }

   &__questions {
      &--bottom {
         display: none;
      }

      @media (max-width: 1123px) {
         display: none;

         &--bottom {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
         }
      }
   }

   &__questions-title {
      color: #002e32;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.4px;
      margin-bottom: 5px;
   }

   &__questions-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.4px;
      margin-bottom: 22px;
   }
}

.social {
   display: flex;
   margin-left: 18px;

   @media (max-width: 1123px) {
      margin-left: 0;
   }

   &__list {
      & + & {
         margin-left: 10px;
      }
   }

   &__link {
      display: block;
      border-radius: 50%;
      object-fit: cover;
      width: 33px;
      height: 32px;
   }
}
.room {
   max-width: 751px;
   width: 100%;
   box-shadow: 0 -3px 24px rgba(0, 55, 84, 0.15);
   border-radius: 25px;
   background-color: #ffffff;

   &__menu {
      border-radius: 25px 25px 5px 5px;
      border: 1px solid #dcdcdc;
      background-color: #ffffff;
   }

   &__list {
      display: flex;
      align-items: center;
      min-height: 50px;
   }

   &__item {
      position: relative;
      padding: 0px 43px 0px 40px;
      min-height: 50px;
      display: flex;
      align-items: center;
      border-right: 1px solid #dcdcdc;

      @media (max-width: 790px) {
         max-width: 33.333%;
         width: 100%;
         text-align: center;
         justify-content: center;
      }

      &--active {
         border-radius: 25px 0 0;
         background-color: rgba(0, 0, 0, 0.25);
         background-color: #11757d;
         background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
      }

      &:last-child {
         display: flex;
         justify-content: flex-end;
         max-width: 220px;
         width: 100%;
         flex: 1 0 285px;
         border-right: none;
         padding: 0px 0px 0px 0px;

         @media (max-width: 790px) {
            display: none;
         }
      }
   }

   // .room__link

   &__link {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.4px;
      color: #282828;
      position: relative;
      cursor: pointer;

      &--active {
         color: #fefefe;
      }
   }

   // .room__link--arrow

   &__link--arrow {
      padding-right: 11px;

      &:after {
         content: "";
         top: 5px;
         right: -6px;
         position: absolute;
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 8px 7px 0 7px;
         border-color: #11767d transparent transparent transparent;
      }
   }

   // .room__link--big

   &__link--big {
   }

   // .room__filter

   &__filter {
   }

   // .room__wrapper

   &__wrapper {
      margin: 21px 11px 11px 11px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 790px) {
         flex-wrap: wrap;
         justify-content: center;
      }
   }

   // .room__card

   &__card {
   }
}
.filter {
   display: flex;
   align-items: flex-end;
   background-color: #f8f8f8;
   position: relative;
   padding: 29px 30px 30px 53px;
   @media (max-width: 790px) {
      flex-direction: column;
      align-items: center;
   }
   @media (max-width: 380px) {
      padding: 29px 15px 30px 15px;
   }
   // .filter__box

   &__box {
      max-width: 159px;
      width: 100%;
      text-align: center;
      & + & {
         margin-left: 31px;
      }
      @media (max-width: 790px) {
         margin-bottom: 30px;

         & + & {
            margin-left: 0;
         }
      }
   }

   // .filter__name

   &__name {
      color: #282828;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.2px;
      margin-bottom: 12px;
   }

   // .filter__calculator

   &__calculator {
      display: flex;
   }

   // .filter__btn

   &__btn {
      margin-left: 40px;
      @media (max-width: 790px) {
         margin-left: 0;
      }
   }
}
.calculator {
   // .calculator__control

   &__control {
      color: #11757d;
      font-size: 35px;
      font-weight: 700;
      letter-spacing: 3.5px;
      border: none;
   }

   &__result {
      color: #282828;
      max-width: 100px;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1.8px;
      border-radius: 18px;
      border: 1px solid #11757d;
      padding: 11px 0px 9px 0px;
      margin: 0px 13px 0px 13px;

      span {
         font-size: 12px;
         font-weight: 400;
         letter-spacing: 1.2px;
      }
   }
}
.result {
}
.btn {
}
.card-room {
   max-width: 360px;
   width: 100%;
   border-radius: 25px;
   border: 1px solid #d9922b;
   padding: 22px 18px 30px 24px;
   margin-bottom: 10px;
   // .card-room__head

   &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 16px 0px;
      border-bottom: 1px solid #b8b8b8;
   }

   // .card-room__head-title

   &__head-title {
      max-width: 215px;
      width: 100%;
      color: #002e32;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      @media (max-width: 380px) {
         max-width: 150px;
      }
   }

   // .card-room__head-sum

   &__head-sum {
      color: #002e32;
      font-size: 24px;
      font-weight: 400;
      line-height: 16px;
      span {
         font-size: 18px;
      }
   }

   // .card-room__list

   &__list {
      margin: 20px 0px 30px 0px;
   }

   // .card-room__item

   &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #002e32;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 1.4px;
      & + & {
         margin-top: 8px;
      }
   }

   // .card-room__btn

   &__btn {
      text-align: center;
      display: flex;
      position: relative;
   }

   // .card-room__btn-name

   &__btn-name {
      display: block;
      max-width: 253px;
      width: 100%;
      border-radius: 23px;
      padding: 16px 24px 16px 24px;
      background-image: linear-gradient(-200deg, #ffb03c 0%, #ff950d 100%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1.6px;
      margin-left: 20px;
      text-align: left;
      @media (max-width: 380px) {
         padding: 16px 24px 16px 14px;
      }
   }

   // .card-room__btn-sum

   &__btn-sum {
      position: absolute;
      right: 42px;
      max-width: 111px;
      width: 100%;
      padding: 16px 24px 16px 24px;
      border-radius: 23px;
      background-color: rgba(0, 0, 0, 0.25);
      background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.6px;
      color: #fff;
      @media (max-width: 380px) {
         right: 0;
      }
      &--popup {
         top: 0;
         right: 25px;
      }
      span {
         font-size: 12px;
         font-weight: 400;
      }
   }
}

.shadov {
   display: block;
   height: 4px;
   margin: 0px 15px 0px 15px;
   border-radius: 25px;
   box-shadow: 0 -7px 9px rgba(0, 55, 84, 0.75);
}

.room {
   // .room__dropdown

   &__dropdown {
   }
}
.dropdown {
   position: absolute;
   z-index: 3;
   top: 50px;
   left: 0;
   min-width: 209px;
   border: 1px solid #e8e8e8;
   background-color: #ffffff;
   display: none;
   &--profile {
      min-width: 250px;
   }
   &--sum {
      @media (max-width: 550px) {
         left: -84px;
      }
   }
   // .dropdown__item

   &__item {
   }

   // .dropdown__link

   &__link {
      position: relative;
      display: block;
      padding: 15px 31px 14px 31px;
      border: 1px solid #e8e8e8;
      background-color: #ffffff;
      color: #282828;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.4px;
      &:after {
         content: "";
         position: absolute;
         left: 12px;
         top: 20px;
         width: 7px;
         height: 7px;
         border-radius: 50%;
         border: 1px solid #11757d;
      }
   }
}

.profile-popup {
   max-width: 600px;
   width: 100%;
   padding: 39px 30px 20px 30px;
   border-radius: 25px;
   background-color: #ffffff;
   text-align: center;

   // .profile-popup__title

   &__title {
      color: #282828;
      font-size: 30px;
      font-weight: 800;
      letter-spacing: 3px;
      margin-bottom: 32px;
   }

   // .profile-popup__inner

   &__inner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 22px;
   }

   // .profile-popup__card

   &__card {
      max-width: 100px;
      width: 100%;
      border-radius: 25px;
      background-color: rgba(0, 0, 0, 0.25);
      background-image: linear-gradient(-200deg, #33a987 0%, #0d6f7c 100%);
      text-align: center;
      margin-bottom: 10px;
      padding: 25px 14px 25px 14px;
      transition: all 0.5s;
      &:hover,
      &:focus,
      &:active {
         background-image: none;
         background-color: #ff950d;
      }
   }

   // .profile-popup__card-title

   &__card-title {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 15px;
   }

   // .profile-popup__card-num

   &__card-num {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      align-items: flex-end;
      color: #ffffff;
      font-size: 30px;
      font-weight: 700;
      line-height: 16px;
   }

   &__btn {
      position: relative;
      max-width: 300px;
      width: 100%;
      min-height: 50px;
      margin: 0 auto;
   }
}

.profile {
   // .profile__history

   &__history {
   }
}
.history {
   // .history__inner

   &__inner {
   }

   // .history__head

   &__head {
      display: flex;
      padding: 18px 20px 23px 20px;
      @media (max-width: 570px) {
         flex-direction: column;
         align-items: center;
      }
   }

   // .history__btn

   &__btn {
      color: #282828;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.4px;
      padding: 13px 20px 11px 20px;
      border-radius: 19px;
      border: none;
      @media (max-width: 570px) {
         margin-bottom: 20px;
      }
   }

   // .history__btn--active

   &__btn--active {
      background-color: #dcdcdc;
   }

   // .history__body

   &__body {
      max-width: 656px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      @media (max-width: 670px) {
         max-width: 100%;
         justify-content: center;
      }
   }
}
.history-body {
   // .history-body__item

   &__item {
      width: 100%;
      display: flex;
      border-bottom: 1px solid #dcdcdc;
      text-align: center;
      &:last-child {
         border-bottom: none;
      }
      &--title {
         border-bottom: none;
      }
   }

   // .history-body__col

   &__col {
      display: block;
      max-width: 243px;
      width: 100%;
      padding: 18px 0px 22px 0px;
      color: #282828;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.4px;
      & + & {
         border-left: 1px solid #dcdcdc;
      }

      @media (max-width: 420px) {
         letter-spacing: normal;
         font-size: 12px;
      }

      &:nth-child(2) {
         max-width: 190px;
         width: 100%;
      }
   }

   // .history-body__col--title

   &__col--title {
      color: #282828;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 1.4px;
   }
}

.profile {
   // .profile__more

   &__more {
      transform: translateX(187px);
      max-width: 280px;
      width: 100%;
      margin: 45px auto 0;
      @media (max-width: 1123px) {
         transform: none;
      }
   }

   // .profile__more-btn

   &__more-btn {
      display: block;
      color: #00757f;
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 1.6px;
      border-radius: 23px;
      border: 1px solid #11757d;
      padding: 16px 64px 16px 24px;
      position: relative;
      &:after {
         content: "";
         position: absolute;
         top: 12px;
         right: 24px;
         width: 22px;
         height: 25px;
         background-image: url("../images/icon/arrow-down-q.png");
      }
   }
}
