.agree {
   margin-top: -100px;
   padding: 0px 0px 54px 0px;
   background-color: #f8f8f8;
   @media (max-width: 1120px) {
      .container {
         padding: 0 0;
      }
   }

   &__inner {
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 1120px) {
         justify-content: center;
      }
   }

   // .agree__text

   &__text {
      max-width: 750px;
      width: 100%;
      padding: 30px 37px 30px 31px;
      margin-right: 30px;
      border-radius: 25px;
      background-color: #ffffff;
      @media (max-width: 1120px) {
         margin-right: 0;
         margin-bottom: 30px;
      }
      @media (max-width: 450px) {
         padding: 30px 20px 30px 20px;
      }
   }

   // .agree__doc

   &__doc {
      max-width: 320px;
      width: 100%;
   }
}
.container {
}
.text {
   text-align: center;
   // .text__descr

   &__descr {
      text-align: left;
      color: #282828;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.4px;
      margin-bottom: 27px;
   }

   &__btn {
      display: none;
      max-width: 194px;
      width: 100%;
      border-radius: 23px;
      border: 1px solid #11757d;
      color: #282828;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.4px;
      padding: 17px 5px 17px 5px;

      @media (max-width: $md3+px) {
         display: inline-block;
      }
   }

   &__hide {
      transition: all 1s;
      @media (max-width: $md3+px) {
         display: none;
      }
   }

   &__list {
      text-align: left;
      color: #282828;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.4px;
      margin-bottom: 27px;
   }
}
.doc {
   &__box {
      color: #ffffff;
      border-radius: 25px;
      background-color: #ffffff;
      margin-bottom: 20px;
      padding: 30px 34px 60px 33px;
      text-align: center;
      &:last-child {
         margin-bottom: 0;
      }
      &--blue {
         background-image: linear-gradient(-240deg, #009ba9 0%, #1fe1aa 100%);
      }
      &--orange {
         background-image: linear-gradient(-200deg, #ffb03c 0%, #ff950d 100%);
      }
   }

   &__descr {
      max-width: 235px;
      width: 100%;
      margin: 0 auto 34px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.6px;
   }

   &__format {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.4px;
      margin-bottom: 40px;
   }
}
