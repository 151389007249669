//<ОСНОВНОЙ БЛОК>====================================================================================================
.title-box {
   max-width: 450px;
   width: 100%;
   margin: 0 auto;
   text-align: center;
   transform: translateY(-123px);
   &--big {
      max-width: 720px;
      @media (max-width: 450px) {
         max-width: 100%;
      }
   }
   // .title__head

   &__head {
      color: #ff950e;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.2px;
      margin-bottom: 5px;
   }

   // .title__slogan

   &__slogan {
      color: #282828;
      font-size: 30px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-left: 2px;
      text-align: center;
      @media (max-width: 450px) {
         max-width: 250px;
         width: 100%;
         margin: 0 auto;
         font-size: 28px;
         &--big {
            max-width: 100%;
         }
      }
   }
   &__descr {
      margin-top: 48px;
      color: #282828;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 1.4px;
      text-align: center;
      @media (max-width: 450px) {
         margin-top: 20px;
      }
   }
}

//<ГОТОВЫЕ КЛАССЫ>====================================================================================================

//====================================================================================================
.video {
   position: relative;
   overflow: hidden;
   height: 0;
   padding-bottom: 56.25%;
   video,
   iframe,
   object,
   embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
   }
}
.videobg {
   video,
   iframe,
   object,
   embed {
      position: fixed;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      background-size: cover;
   }
}
//====================================================================================================
.moretext {
   overflow: hidden;
}
.moretext__more {
   cursor: pointer;
   span {
      font-style: normal;
      &:first-child {
         display: block;
      }
      &:last-child {
         display: none;
      }
   }
   &.active {
      span {
         font-style: normal;
         &:first-child {
            display: none;
         }
         &:last-child {
            display: block;
         }
      }
   }
}
//====================================================================================================
.graystyle {
   @include anim(0.8s, 0s);
   filter: grayscale(1);
   -webkit-filter: grayscale(1);
   -moz-filter: grayscale(1);
   -o-filter: grayscale(1);
}
.graystyleoff {
   filter: grayscale(0);
   -webkit-filter: grayscale(0);
   -moz-filter: grayscale(0);
   -o-filter: grayscale(0);
}
//====================================================================================================
.slick-slider {
   position: relative;
   .slick-track,
   .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
   }
   .slick-list {
      position: relative;
      overflow: hidden;
      width: 100%;
   }
   .slick-track {
      position: relative;
      width: 100%;
      @include flexbox();
   }
   .slick-slide {
      position: relative;
   }
   .slick-arrow {
      &.slick-prev {
      }
      &.slick-next {
      }
   }
   .slick-dots {
      li {
         button {
         }
         &.slick-active {
            button {
            }
         }
      }
   }
}
//=====================================================================================================================

//=====================================================================================================================
//=====================================================================================================================

//=====================================================================================================================

//</ГОТОВЫЕ КЛАССЫ>====================================================================================================
