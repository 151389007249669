.footer {
   min-height: 280px;
   background-color: #252c41;
   margin-top: -150px;
   background-repeat: no-repeat;
   background-position: center center;
   background-size: cover;
   @media (max-width: 450px) {
      background-image: none !important;
   }
   &--faq {
      margin-top: 0;
   }
   @media (max-width: 1070px) {
      margin-top: 0;
   }

   &__inner {
      padding: 182px 0px 0px 0px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &--account {
         padding: 120px 0px 0px 0px;
      }
      @media (max-width: 1070px) {
         padding: 100px 0px 0px 0px;
      }
      @media (max-width: 860px) {
         padding: 50px 0px 45px 0px;
         flex-direction: column;
         align-items: center;
      }
   }

   &__copy-link {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 47px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ffffff;
      @media (max-width: 860px) {
         display: flex;
         margin: 59px auto;
      }
   }

   &__copy-text {
      color: #ffffff;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 1.4px;
      transition: all 1s;
      &:hover,
      &:focus {
         opacity: 0.7;
      }
   }

   &__menu {
      display: inline-flex;
      align-items: center;

      @media (max-width: 860px) {
         flex-direction: column;
         text-align: center;
      }
   }

   // .footer__item

   &__item {
      & + & {
         margin-left: 30px;
      }
      @media (max-width: 860px) {
         & + & {
            margin-left: 0px;
            margin-top: 23px;
         }
      }
   }

   &__link {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.4px;
      transition: all 1s;
      border-radius: 14px;
      border: 1px solid transparent;
      padding: 4px 15px 4px 15px;
      span {
         display: none;
      }
      @media (max-width: 860px) {
         font-size: 22px;
         img {
            display: none;
         }
         span {
            display: block;
         }
      }
      @media (min-width: 992px) {
         &:hover,
         &:focus,
         &:active {
            border: 1px solid #ffffff;
         }
      }
   }
}
